import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { CanvasHelpers } from 'src/app/helpers/canvas-helpers.class';
import { Results, SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { Results as FaceMeshResults, FaceMesh } from '@mediapipe/face_mesh';
import { Effect } from '../../models/effect';
import { FaceMeshEffectRenderer } from './../../classes/face-mesh-effect-renderer.class';
import { FaceMeshEffectRendererOptions } from '../../models/face-mesh-effect-renderer-options';
import * as THREE from 'three';

interface Assets {
  overlayImg: HTMLImageElement;
  borderImg: HTMLImageElement;
  stickerImg: HTMLImageElement;
  backgroundImg: HTMLImageElement;
  faceMeshOptions: FaceMeshEffectRendererOptions;
}

interface DrawCoordinatesAndDimensions {
  drawX: number;
  drawY: number;
  drawWidth: number;
  drawHeight: number;
}

@Component({
  selector: 'hi-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class HoudiniVideoComponent implements AfterViewInit {
  @Input()
  public developerMode: boolean = false;

  @Input()
  public stream: MediaStream = undefined;

  @Input()
  public flipVideo: boolean = true;

  @Input()
  public enableVirtualBackgrounds: boolean = true;

  @Input()
  public virtualBackgroundFullHeight: boolean = true;

  @Input()
  public enableFaceMeshFeature: boolean = true;

  @Input()
  public faceMeshPerformanceMode: boolean = false;

  @Input()
  public filter: Effect = null;

  @Input()
  public set overlay(overlay: Effect) {
    if (!overlay || !overlay.data) {
      this.assets.overlayImg = null;
      return;
    }
    this.assets.overlayImg = new Image();
    this.assets.overlayImg.crossOrigin = 'Anonymous';
    this.assets.overlayImg.src = overlay.data;
  }

  @Input()
  public set border(border: Effect) {
    if (!border || !border.data) {
      this.assets.borderImg = null;
      return;
    }
    this.assets.borderImg = new Image();
    this.assets.borderImg.crossOrigin = 'Anonymous';
    this.assets.borderImg.src = border.data;
  }

  @Input()
  public set sticker(sticker: Effect) {
    if (!sticker || !sticker.data) {
      this.assets.stickerImg = null;
      return;
    }
    this.assets.stickerImg = new Image();
    this.assets.stickerImg.crossOrigin = 'Anonymous';
    this.assets.stickerImg.src = sticker.data;
  }

  @Input()
  public set background(background: Effect) {
    if (!background || !background.data || background.empty) {
      this.assets.backgroundImg = null;
      return;
    }
    this.assets.backgroundImg = new Image();
    this.assets.backgroundImg.crossOrigin = 'Anonymous';
    this.assets.backgroundImg.src = background.data;
  }

  @Input()
  public set faceEffect(faceEffect: Effect) {
    if (!faceEffect || !faceEffect.data || faceEffect.empty) {
      this.assets.faceMeshOptions = null;
      this.faceMeshEffectRenderer = null;
      return;
    }

    this.assets.faceMeshOptions = faceEffect.data;

    if (!this.faceMeshEffectRenderer) {
      this.initFaceMeshEffectRenderer();
    }

    this.faceMeshEffectRenderer.loadModel(this.assets.faceMeshOptions);
  }

  public videoReady: boolean = false;

  private clock: THREE.Clock = new THREE.Clock();
  private clockDelta = 0;
  private targetFPS = 1 / 60;

  private startTime: number = Date.now();

  private lastFrameTime: number = 0.0;

  public fps: number = 0.0;

  public fpsHistory: number[] = [];

  @Output()
  onVideoReady: EventEmitter<void> = new EventEmitter<void>();

  private raf: any = undefined;

  // Video Input
  private video: HTMLVideoElement = undefined;

  // Canvas
  private canvas: HTMLCanvasElement = undefined;
  private ctx: CanvasRenderingContext2D = undefined;

  //Human Segmentation
  private selfieSegmentation: SelfieSegmentation = undefined;
  private selfieSegmentationResults: Results = null;

  //Face Mesh
  private faceMesh: FaceMesh = undefined;
  private faceMeshResults: FaceMeshResults = null;
  private faceMeshEffectRenderer: FaceMeshEffectRenderer = null;

  private canvasScale: number = window.devicePixelRatio;

  private drawCoordinatesAndDimensions: DrawCoordinatesAndDimensions = {
    drawX: 0,
    drawY: 0,
    drawWidth: 0,
    drawHeight: 0,
  };

  public assets: Assets = {
    overlayImg: null,
    borderImg: null,
    stickerImg: null,
    backgroundImg: null,
    faceMeshOptions: null,
  };

  //
  //
  // Lifecycle
  //
  async ngAfterViewInit() {
    this.video = document.querySelector('.capture-video');
    this.canvas = document.querySelector('.capture-canvas');
    this.ctx = this.canvas.getContext('2d');

    await this.checkstream();
  }

  ngOnDestroy(): void {
    console.log('VideoComponent.ngOnDestroy');
    this.stopStream();
    CanvasHelpers.clearInMemoryCanvases();
    this.raf = undefined;
    this.assets = {
      overlayImg: null,
      borderImg: null,
      stickerImg: null,
      backgroundImg: null,
      faceMeshOptions: null,
    };
    this.faceMeshEffectRenderer = null;
    this.video = undefined;
    this.canvas = undefined;
    this.ctx = undefined;
  }

  private loop(): void {
    this.raf = requestAnimationFrame(this.loop.bind(this));
    // this.clockDelta += this.clock.getDelta();
    // if(this.clockDelta > this.targetFPS) {
    this.processSelfieSegmentation();
    this.processFaceMesh();
    this.processCanvas();

    let now = Date.now();
    let tot = now - this.lastFrameTime;
    this.lastFrameTime = now;

    let frameDelay = 1.0 / (tot / 1000.0);

    this.updateFPS(frameDelay);
    // }
  }

  updateFPS(val: number) {
    this.fpsHistory.push(val);

    if (this.fpsHistory.length > 60) {
      this.fpsHistory.shift();
    }

    let tot = 0;
    this.fpsHistory.forEach((v) => {
      tot += v;
    });

    this.fps = tot / this.fpsHistory.length;
  }

  private async processCanvas(): Promise<any> {
    this.setCanvasSizes();
    this.calculateDrawCoordinatesAndDimensions();

    this.drawVirtualBackground(this.ctx);
    this.drawOriginalSourceVideo(this.ctx);
    this.drawFaceEffects(this.ctx);
    // this.drawOverlayImage();
    // this.drawBorderImage();
    // this.drawSticker();
  }

  //
  //
  // Media Capture
  //
  public async takeScreenshot(quality: number = 0.85): Promise<string> {
    let canvas = CanvasHelpers.createInMemoryCanvas('screenshot-canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = this.canvas.width;
    canvas.height = this.canvas.height;
    await this.processSelfieSegmentation();
    this.drawVirtualBackground(ctx);
    this.drawOriginalSourceVideo(ctx);
    this.drawFaceEffects(ctx);
    this.drawOverlayImage(ctx);
    this.drawBorderImage(ctx);
    this.drawSticker(ctx);

    return CanvasHelpers.takeScreenshot(canvas, ctx, quality);
  }

  // TODO: WE NEED A FLAG TO KNOW IF WE ARE RECORDING VIDEO OR NOT SO THAT WE CAN
  // DRAW EVERYTHING INTO THE CANVAS WHILE RECORDING.
  // NOTE: THIS WILL REDUCE FRAMERATE.
  public async takeVideo(seconds: number = 5000): Promise<string> {
    return CanvasHelpers.takeVideo(this.canvas, seconds);
  }

  //
  //
  // Media Inputs
  //
  public async stopStream() {
    cancelAnimationFrame(this.raf);
    if (this.selfieSegmentation) {
      await this.selfieSegmentation.close();
      this.selfieSegmentation = undefined;
    }

    if (this.faceMesh) {
      await this.faceMesh.close();
      this.faceMesh = undefined;
    }

    this.stream = undefined;
  }

  public async checkstream() {
    if (this.stream) {
      if (this.enableVirtualBackgrounds && !this.selfieSegmentation) {
        await this.initSelfieSegmentation();
      }

      if (this.enableFaceMeshFeature && !this.faceMesh) {
        await this.initFaceMesh();
      }

      this.video.play();
      this.video.onplaying = () => {
        this.loop();
        setTimeout(() => {
          this.videoReady = true;
          this.onVideoReady.emit();
        });
      };
    } else {
      setTimeout(async () => {
        await this.checkstream();
      }, 100);
    }
  }

  //
  //
  // Process Helpers
  //
  private drawOverlayImage(ctx: CanvasRenderingContext2D) {
    if (!this.assets.overlayImg) {
      return;
    }

    this.drawImage(this.assets.overlayImg, ctx);
  }

  private drawBorderImage(ctx: CanvasRenderingContext2D) {
    if (!this.assets.borderImg) {
      return;
    }

    this.drawImage(this.assets.borderImg, ctx);
  }

  private drawSticker(ctx: CanvasRenderingContext2D) {
    if (!this.assets.stickerImg) {
      return;
    }

    this.drawImage(this.assets.stickerImg, ctx);
  }

  private drawImage(image: HTMLImageElement, ctx: CanvasRenderingContext2D) {
    CanvasHelpers.drawImage(
      ctx,
      image,
      0,
      this.canvas.height / 2 - this.canvas.width / 2,
      this.canvas.width,
      this.canvas.width,
      0,
      false
    );
  }

  private setCanvasSizes() {
    // if(Date.now() - this.startTime > 1000) {
    //   this.startTime = Date.now();
    //   if(this.fps < 20 && this.canvasScale > 0.75) {
    //     this.canvasScale -= 0.25;
    //   } else if(this.fps > 30 && this.canvasScale < 3) {
    //     this.canvasScale += 1;
    //   }
    // }
    // console.log(`VIDEO SIZE: ${this.video.offsetWidth}:${this.video.offsetHeight} -- SCALE: ${this.canvasScale}`)

    this.canvas.width = this.video.offsetWidth * this.canvasScale;
    this.canvas.height = this.video.offsetHeight * this.canvasScale;

    // console.log(`CANVAS SIZE: ${this.canvas.width}:${this.canvas.height} -- SCALE: ${this.canvasScale}`)
    // console.log(`SCREEN SIZE: ${window.screen.width}:${window.screen.height} -- ${window.screen.width * window.devicePixelRatio}:${window.screen.height * window.devicePixelRatio} -- PXRatio: ${window.devicePixelRatio}`)
  }

  private calculateDrawCoordinatesAndDimensions() {
    const scaledW =
      this.video.videoWidth * (this.canvas.height / this.video.videoHeight);

    this.drawCoordinatesAndDimensions.drawX = (this.canvas.width - scaledW) / 2;
    this.drawCoordinatesAndDimensions.drawY = 0;
    this.drawCoordinatesAndDimensions.drawWidth = scaledW;
    this.drawCoordinatesAndDimensions.drawHeight =
      this.video.offsetHeight * this.canvasScale;
  }

  //
  //
  // Image Backgrounds
  //
  private async initSelfieSegmentation() {
    if (this.selfieSegmentation) return;

    this.selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      },
    });

    this.selfieSegmentation.setOptions({
      modelSelection: 0,
    });

    this.selfieSegmentation.onResults((results) => {
      this.selfieSegmentationResults = results;
    });

    await this.selfieSegmentation.initialize();
  }

  private get shouldDrawVirtualBackground(): boolean {
    return (
      this.enableVirtualBackgrounds &&
      this.selfieSegmentationResults &&
      !!this.assets.backgroundImg
    );
  }

  //
  // Camera Backgrounds
  //
  private drawOriginalSourceVideo(ctx: CanvasRenderingContext2D) {
    if (this.shouldDrawVirtualBackground) {
      return;
    }
    CanvasHelpers.drawImage(
      ctx,
      this.video,
      this.drawCoordinatesAndDimensions.drawX,
      this.drawCoordinatesAndDimensions.drawY,
      this.drawCoordinatesAndDimensions.drawWidth,
      this.drawCoordinatesAndDimensions.drawHeight,
      0,
      this.flipVideo,
      this.filter?.data
    );
  }

  private drawVirtualBackground(ctx: CanvasRenderingContext2D) {
    if (!this.shouldDrawVirtualBackground) {
      return;
    }

    // console.log('DRAWING VIRTUAL BACKGROUND');
    ctx.save();

    CanvasHelpers.drawImage(
      ctx,
      this.selfieSegmentationResults.segmentationMask,
      this.drawCoordinatesAndDimensions.drawX,
      this.drawCoordinatesAndDimensions.drawY,
      this.drawCoordinatesAndDimensions.drawWidth,
      this.drawCoordinatesAndDimensions.drawHeight,
      0,
      this.flipVideo
    );

    ctx.globalCompositeOperation = 'source-in';

    CanvasHelpers.drawImage(
      ctx,
      this.selfieSegmentationResults.image,
      this.drawCoordinatesAndDimensions.drawX,
      this.drawCoordinatesAndDimensions.drawY,
      this.drawCoordinatesAndDimensions.drawWidth,
      this.drawCoordinatesAndDimensions.drawHeight,
      0,
      this.flipVideo,
      this.filter?.data
    );

    ctx.globalCompositeOperation = 'destination-atop';

    CanvasHelpers.drawImageCover(
      ctx,
      this.assets.backgroundImg,
      0,
      this.virtualBackgroundFullHeight
        ? 0
        : this.canvas.height / 2 - this.canvas.width / 2,
      this.canvas.width,
      this.canvas.height
    );

    ctx.restore();
  }

  private async processSelfieSegmentation() {
    if (
      this.enableVirtualBackgrounds &&
      this.selfieSegmentation &&
      this.video &&
      this.stream &&
      this.assets.backgroundImg
    ) {
      // console.log('SELFIE SEGMENTATION');
      await this.selfieSegmentation.send({ image: this.video });
    }
  }

  //
  // Face Mesh
  //
  private get shouldDrawFaceEffects(): boolean {
    return (
      this.enableFaceMeshFeature &&
      this.faceMeshResults &&
      !!this.faceMeshEffectRenderer
    );
  }

  private async initFaceMesh() {
    if (this.faceMesh) return;

    this.faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    this.faceMesh.setOptions({
      selfieMode: false,
      enableFaceGeometry: true,
      maxNumFaces: 1,
      refineLandmarks: false,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    this.faceMesh.onResults((results) => {
      this.faceMeshResults = results;
    });

    await this.faceMesh.initialize();
  }

  private faceMeshScale(): number {
    if (this.canvasScale > 2) {
      return this.canvasScale / 2;
    } else {
      return 1;
    }
  }

  private initFaceMeshEffectRenderer() {
    const faceMeshCanvas = CanvasHelpers.createInMemoryCanvas('faceMeshCanvas');
    let scale = this.faceMeshScale();
    faceMeshCanvas.width = this.video.videoWidth * scale;
    faceMeshCanvas.height = this.video.videoHeight * scale;
    this.faceMeshEffectRenderer = new FaceMeshEffectRenderer(
      faceMeshCanvas,
      'assets/photobooth/face-mesh/'
    );
  }

  private async processFaceMesh() {
    if (this.faceMeshPerformanceMode && this.fps <= 50) return;

    if (
      this.enableFaceMeshFeature &&
      this.faceMesh &&
      this.video &&
      this.stream &&
      this.assets.faceMeshOptions
    ) {
      await this.faceMesh.send({ image: this.video });
    }
  }

  private drawFaceEffects(ctx: CanvasRenderingContext2D) {
    if (!this.shouldDrawFaceEffects) {
      return;
    }

    this.faceMeshEffectRenderer.render(this.faceMeshResults);
    CanvasHelpers.drawImage(
      ctx,
      CanvasHelpers.getCanvas('faceMeshCanvas'),
      this.drawCoordinatesAndDimensions.drawX,
      this.drawCoordinatesAndDimensions.drawY,
      this.drawCoordinatesAndDimensions.drawWidth,
      this.drawCoordinatesAndDimensions.drawHeight,
      0,
      this.flipVideo,
      this.filter?.data
    );
  }
}

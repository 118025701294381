import { Injectable } from '@angular/core';
import { Form } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FormData } from 'src/app/models/configuration';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public requestUserData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dataConfirmed: BehaviorSubject<FormData|undefined> = new BehaviorSubject<FormData|undefined>(undefined);
  public dataCallback: ()=>{}|null

  nameKey: string = 'userName'
  emailKey: string = 'userEmail'
  termsKey: string = 'termsAccepted'

  constructor() { }

  public updateUserData(data: FormData) {
    localStorage.setItem(this.nameKey, data.name)
    localStorage.setItem(this.emailKey, data.email)
    localStorage.setItem(this.termsKey, data.terms.toString())
  }

  public userData(): FormData | undefined {
    let name = localStorage.getItem(this.nameKey);
    let email = localStorage.getItem(this.nameKey);
    let terms = localStorage.getItem(this.nameKey);
    if(name && email && terms) {
      return {
        "name": name,
        "email": email,
        "terms": true
      }
    } else {
      return undefined
    }
  }

  public getUserData() {
   this.requestUserData.next(true);
  }
}

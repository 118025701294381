import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { WelcomePageData } from 'src/app/models/page-data-types/welcome-page-data';

@Component({
  selector: 'hi-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent
  extends PageBase<WelcomePageData>
  implements OnInit
{
  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { CameraFeedPageComponent } from './pages/camera-feed-page/camera-feed-page.component';
import { ThankYouPageComponent } from './pages/thank-you-page/thank-you-page.component';
import { GatePageComponent } from './pages/gate-page/gate-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { CameraAccessPromptPageComponent } from './pages/camera-access-prompt-page/camera-access-prompt-page.component';
import { UserInformationPageComponent } from './pages/user-information-page/user-information-page.component';
import { SubmissionPageComponent } from './pages/submission-page/submission-page.component';
import { GalleryEmbedComponent } from './pages';
import { PageRoutes } from './constants/page-routes.const';

const routes: Routes = [
  {
    path: PageRoutes.LANDING,
    component: LandingPageComponent,
  },
  {
    path: PageRoutes.GATE,
    component: GatePageComponent,
  },
  {
    path: PageRoutes.WELCOME,
    component: WelcomePageComponent,
  },
  {
    path: PageRoutes.CAMERA_ACCESS,
    component: CameraAccessPromptPageComponent,
  },
  {
    path: PageRoutes.USER_INFORMATION,
    component: UserInformationPageComponent,
  },
  {
    path: PageRoutes.CAMERA_FEED,
    component: CameraFeedPageComponent,
  },
  {
    path: PageRoutes.SUBMISSION,
    component: SubmissionPageComponent,
  },
  {
    path: PageRoutes.THANK_YOU,
    component: ThankYouPageComponent,
  },
  {
    path: PageRoutes.GALLERY_EMBED,
    component: GalleryEmbedComponent,
  },
  //{ path: '**', redirectTo: '' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot([], { initialNavigation: 'disabled', enableTracing: false } )],
  exports: [RouterModule],
})
export class AppRoutingModule {}

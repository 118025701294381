import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { HiCameraService } from '../../services/camera.service';

@Component({
  selector: 'hi-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class HoudiniCameraComponent implements OnInit, OnDestroy {
  @Input()
  constraints: MediaStreamConstraints = null;

  @Output()
  onCameraAccessAllowed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCameraAccessDeclined: EventEmitter<void> = new EventEmitter<void>();

  private mediaStream: MediaStream = undefined;

  constructor(private cameraService: HiCameraService) {}

  ngOnInit(): void {
    this.init(this.constraints);
  }

  ngOnDestroy(): void {
    this.mediaStream = undefined;
    this.cameraService.destroyCamera();
  }

  public init(constraints: MediaStreamConstraints = null) {
    this.cameraService.initCamera(null, constraints).then(
      (result) => {
        console.log('Camera access allowed');
        this.onCameraAccessAllowed.emit();
        this.mediaStream = result.stream;
      },
      (error) => {
        console.log('Camera access declined');
        this.onCameraAccessDeclined.emit();
      }
    );
  }

  public destroy() {
    this.cameraService.destroyCamera();
  }

  public get stream() {
    return this.mediaStream;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Effect } from 'src/app/models/effect';

@Component({
  selector: 'hi-bottom-controls',
  templateUrl: './bottom-controls.component.html',
  styleUrls: ['./bottom-controls.component.scss'],
})
export class BottomControlsComponent implements OnInit {
  @Input()
  public show: boolean = false;

  @Input()
  public set effects(effects: Effect[]) {
    this._effects = effects;
    this.initItems();
  }

  public get effects(): Effect[] {
    return this._effects;
  }

  @Output()
  public onEffectChanged: EventEmitter<Effect> = new EventEmitter<Effect>();

  @Output()
  public onTakePictureTap: EventEmitter<void> = new EventEmitter<void>();

  public currentCarouselPageIndex: number = 0;

  public selectedEffect: Effect = null;

  private _effects: Effect[] = [];

  constructor() {}

  ngOnInit(): void {}

  public moveCarouselLeft() {
    console.log('left');
    if (this.currentCarouselPageIndex > 0) {
      this.currentCarouselPageIndex--;
    } else {
      this.currentCarouselPageIndex = this.effects.length - 1;
    }

    this.selectedEffect = this.currentCarouselPage[2];

    this.onEffectChanged.emit(this.selectedEffect);
  }

  public moveCarouselRight() {
    console.log('right');
    if (this.currentCarouselPageIndex + 1 < this.effects.length) {
      this.currentCarouselPageIndex++;
    } else {
      this.currentCarouselPageIndex = 0;
    }

    this.selectedEffect = this.currentCarouselPage[2];

    this.onEffectChanged.emit(this.selectedEffect);
  }

  public get currentCarouselPage(): Effect[] {
    if (!this.effects || !this.effects.length) return [];

    let carouselPage = this.effects.slice(
      this.currentCarouselPageIndex,
      5 + this.currentCarouselPageIndex
    );
    if (carouselPage.length < 5) {
      carouselPage = carouselPage.concat(
        this.effects.slice(0, 5 - carouselPage.length)
      );
    }

    return carouselPage;
  }

  public effectById(index: number, effect: any) {
    return effect.id;
  }

  public selectEffect(effect: any) {
    if (this.currentCarouselPage.indexOf(effect) === 2) {
      // take a photo
      this.onTakePictureTap.emit();
    } else if (this.currentCarouselPage.indexOf(effect) > 2) {
      this.moveCarouselRight();
    } else {
      this.moveCarouselLeft();
    }
  }

  private initItems() {
    if (!this.effects) return;

    if (this.effects.length < 6) {
      this.effects = this.effects.concat(
        this.effects.slice(0, 6 - this.effects.length).map((x) => {
          return { ...x };
        })
      );
    }

    let id = 0;

    this.effects.forEach((effect) => {
      effect.id = id;
      id++;
    });

    this.currentCarouselPageIndex = this.effects.length - 2;

    this.selectedEffect = this.currentCarouselPage[2];

    this.onEffectChanged.emit(this.selectedEffect);
  }
}

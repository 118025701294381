import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Button, FormData } from 'src/app/models/configuration';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'hi-data-capture-modal',
  templateUrl: './data-capture-modal.component.html',
  styleUrls: ['./data-capture-modal.component.scss']
})
export class DataCaptureModalComponent implements OnInit {

  @ViewChild('form') ngForm: NgForm;

  show: boolean = false;
  title?: string = "Details"
  copy?: string = "Fill out the form below to get started!"
  callback: any

  public formData: FormData = {
    name: '',
    email: '',
    terms: false
  };

  public formWasSubmitted: boolean = false;

  constructor(private dataSvc: DataService, private modalSvc: ModalService) {
    this.dataSvc.requestUserData.subscribe((show) => {
      this.show = show;
    })
  }

  ngOnInit(): void {
    this.formData.name = localStorage.getItem('userName') || '';
    this.formData.email = localStorage.getItem('userEmail') || '';
    this.formData.terms = localStorage.getItem('termsExcepted') ? true : false;
  }

  /**
   * Show Modal with terms and conditions
   */
  public showTerms(): void {
    this.modalSvc.setModalData({ title: "terms title", copy: "terms copy" });
  }

  // public buttonClick(button: Button): void {
  //   if (button.link === 'submit-user-info-form') {
  //     this.submit();
  //   } else {
  //     super.buttonClick(button);
  //   }
  // }

  /**
   * Submit form and validate
   */
  public submit(): void {
    console.log('Form submitted');
    console.log('Is Form Valid', this.ngForm.valid);

    this.formWasSubmitted = true;

    if (this.ngForm.invalid) return;

    console.log('Send submitted field here');

    localStorage.setItem('userName', this.formData.name);
    localStorage.setItem('userEmail', this.formData.email);
    localStorage.setItem('termsExcepted', this.formData.terms.toString());

    let data = this.dataSvc.userData()
    this.dataSvc.dataConfirmed.next(data);

    this.dismiss();
  }

  public dismiss(): void {
    this.dataSvc.requestUserData.next(false);
  }

  public close(): void {
    this.dismiss();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Configuration } from '../models/configuration';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  
  /**
   * Set to true to pull local json file (env var) vs real api call
   */
  private debug: boolean = false;
  
  public boothName: string;

  public trackingEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public useFullPage$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {}

  public onConfigurationLoaded: Subject<Configuration> =
    new Subject<Configuration>();

  private _loadedConfiguration: Configuration = null;

  public loadConfiguration(): void {
    let endPoint: string = (this.debug ? environment.localConfig : environment.fetchAppConfigUrl + this.boothName);

    console.log(endPoint);
    this.httpClient
      .get<Configuration>(endPoint)
      .subscribe((configuration) => {
        this._loadedConfiguration = configuration;
        this.onConfigurationLoaded.next(configuration);
      });
  }

  public get configuration(): Configuration {
    return this._loadedConfiguration;
  }
}
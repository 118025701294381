<div
  class="page full layout-vertical-space-between gate-page"
  [ngStyle]="{
    'background-image': 'url(' + page.page_background_image_url + ')'
  }"
>
  <div *ngIf="page.page_logo_image_url" class="logo-wrapper">
    <img [src]="page.page_logo_image_url" [alt]="boothName" />
  </div>

  <div class="inner-content">
    <h1>
      <div *ngIf="!userHaveDeclined" [innerHTML]="page.title"></div>
      <div *ngIf="userHaveDeclined" [innerHTML]="page.declined_text"></div>
    </h1>

    <p *ngIf="page.copy" class="base-copy" [class.hide]="userHaveDeclined" [innerHTML]="page.copy"></p>

    <div *ngIf="buttons?.length" class="buttons-wrapper">
      <hi-buttons [buttons]="buttons" (buttonClickEmit)="buttonClick($event)"></hi-buttons>
    </div>
  </div>

  <hi-powered-by *ngIf="poweredByEnabled" [black]="poweredByDark"></hi-powered-by>

  <div *ngIf="page.page_footer_image_url" class="page-footer">
    <img [src]="page.page_footer_image_url" alt="Footer Image">
  </div>
</div>

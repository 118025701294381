<div
  class="page full layout-vertical-center camera-access-page"
  [ngStyle]="{
    'background-image': 'url(' + page.page_background_image_url + ')'
  }"
>
  <hi-camera
    *ngIf="promptForCameraAccess"
    (onCameraAccessAllowed)="onCameraAccessAllowed()"
    (onCameraAccessDeclined)="onCameraAccessDeclined()"
  ></hi-camera>

  <div>
    <div class="camera-icon-wrapper">
      <hi-icon [src]="'assets/icons/icon-camera-2.svg'"></hi-icon>
    </div>

    <h1 *ngIf="cameraAccessStatus === 'PENDING'">Accessing your camera...</h1>
    <h1 *ngIf="cameraAccessStatus === 'DECLINED'">Please allow access to your camera</h1>
    <h1 *ngIf="cameraAccessStatus === 'ALLOWED'">Camera access granted</h1>
  </div>
</div>

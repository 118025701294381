<div class="page full gallery-embed-page" [style.backgroundImage]="page.page_background_image_url ? 'url('+page.page_background_image_url+')' : ''" (scroll)="onScroll()">
    <div *ngIf="page.page_logo_image_url" class="logo-wrapper">
        <img [src]="page.page_logo_image_url" [alt]="boothName" />
    </div>

    <div class="inner-content">
        <h1 *ngIf="page.title" [innerHTML]="page.title"></h1>
        <p *ngIf="page.copy" class="base-copy" [innerHTML]="page.copy"></p>
        <div *ngIf="buttons?.length" class="buttons-wrapper">
            <hi-buttons [buttons]="buttons" (buttonClickEmit)="buttonClick($event)"></hi-buttons>
        </div>

        <div class="images">
            <ng-container *ngIf="images.length && !loading">
                <div class="gimg" *ngFor="let img of images">
                    <img [src]="img.url_thumbnail ? img.url_thumbnail : img.url" [alt]="boothName">
                </div>
            </ng-container>

            <div *ngIf="loading">
                <hi-loader
                    [size]="64"
                    [thickness]="4"
                    [color]="'var(--primary-color)'"
                ></hi-loader>
            </div>
        </div>
    </div>

    <hi-powered-by *ngIf="poweredByEnabled" [black]="poweredByDark"></hi-powered-by>

    <div *ngIf="page.page_footer_image_url" class="page-footer">
        <img [src]="page.page_footer_image_url" alt="Footer Image">
    </div>
</div>
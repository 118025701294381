import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
    private galleryAction: string = 'hi_list_submissions';
    private uploadAction: string = 'hi_upload_photo';
    private registerAction: string = 'hi_register';

    constructor(
        private http: HttpClient
    ) {
    }

    public submitGalleryImage(data: any): any {
        return this.http.post(environment.endpointBaseUrl + this.uploadAction, data);
    }

    public getGalleryImages(slug: string): any {
        return this.http.get(`https://hibackend.wpengine.com/wp-json/hi-photobooth/v1/gallery/${slug}`);
    }
}

import { NumberSymbol } from '@angular/common';

export class CanvasHelpers {
  public static canvases: { [key: string]: HTMLCanvasElement } = {};

  public static drawImage(
    ctx: CanvasRenderingContext2D,
    img: any,
    x: number,
    y: number,
    width: number,
    height: number,
    deg: number = 0,
    flip: boolean = false,
    filter: string = null
  ) {
    // save current context before applying transformations
    ctx.save();
    // convert degrees to radians
    if (flip) {
      var rad = (deg * Math.PI) / 180;
    } else {
      var rad = 2 * Math.PI - (deg * Math.PI) / 180;
    }
    // set the origin to the center of the image
    ctx.translate(x + width / 2, y + height / 2);
    // rotate the canvas around the origin
    ctx.rotate(rad);
    if (flip) {
      // flip the canvas
      ctx.scale(-1, 1);
    }

    if (filter) {
      ctx.filter = filter;
    }
    // draw the image
    ctx.drawImage(img, -width / 2, -height / 2, width, height);
    // restore the canvas
    ctx.restore();
  }

  public static drawImageCover(
    ctx: CanvasRenderingContext2D,
    img: any,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetX?: number,
    offsetY?: number
  ) {
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
    offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r, // new prop. width
      nh = ih * r, // new prop. height
      cx,
      cy,
      cw,
      ch,
      ar = 1;

    // decide which gap to fill
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  public static async takeScreenshot(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,

    quality: number = 0.85
  ): Promise<string> {
    const imageData = context.getImageData(
      0,
      canvas.height / 2 - canvas.width / 2,
      canvas.width,
      canvas.width
    );

    const tmpCanvas = document.createElement('canvas');
    const ctx = tmpCanvas.getContext('2d');
    tmpCanvas.width = canvas.width;
    tmpCanvas.height = canvas.width;
    ctx.putImageData(imageData, 0, 0);

    return tmpCanvas.toDataURL('image/jpeg', quality);
  }

  public static takeVideo(canvas: any, time: number = 5000): Promise<string> {
    const recordedChunks = [];
    return new Promise(function (resolve, reject) {
      const stream = canvas.captureStream(25 /*fps*/);
      const recorder = new MediaRecorder(stream, {
        mimeType: 'video/webm; codecs=vp9',
      });

      //ondataavailable will fire in interval of `time || 4000 ms`
      recorder.start(time || 5000);

      recorder.ondataavailable = function (event) {
        recordedChunks.push(event.data);
        // after stop `dataavilable` event run one more time
        if (recorder.state === 'recording') {
          recorder.stop();
        }
      };

      recorder.onstop = function (event) {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        resolve(url);
      };
    });
  }

  public static createInMemoryCanvas(
    canvasUniqueId: string
  ): HTMLCanvasElement {
    if (this.canvases[canvasUniqueId]) return this.canvases[canvasUniqueId];

    this.canvases[canvasUniqueId] = document.createElement('canvas');

    return this.canvases[canvasUniqueId];
  }

  public static clearInMemoryCanvases() {
    this.canvases = {};
  }

  public static getCanvas(canvasUniqueId: string): HTMLCanvasElement {
    if (!this.canvases[canvasUniqueId])
      throw new Error(`Canvas with id "${canvasUniqueId}" was not created`);

    return this.canvases[canvasUniqueId];
  }

  public static canvasExists(canvasUniqueId: string): boolean {
    return !!this.canvases[canvasUniqueId];
  }

  public static getAllCanvas() {
    return Object.values(this.canvases)
  }

  public static setCanvasSize(
    canvasUniqueId: string,
    width: NumberSymbol,
    height: number
  ): void {
    const canvas = this.getCanvas(canvasUniqueId);
    canvas.width = width;
    canvas.height = height;
  }
}

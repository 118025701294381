import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
// import { Modal } from '../models';


@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public visible = new BehaviorSubject(false);
    public modalData = new BehaviorSubject(undefined);

    constructor(
        private router: Router
    ) { }

    setActiveModalById(id: string, fromLink: any = undefined) {
        // const data: Modal = Modals.find(el => el.id === id);
        // this.setModalData(data, fromLink);
    }

    setActiveModalByData(data, fromLink: any = undefined) {
        this.setModalData(data, fromLink);
    }

    setModalData(data, fromLink: any = undefined): void {
        if (!data) {
            return;
        }

        
        this.modalData.next(data);
        if (!this.visible.value) {
            this.visible.next(true);
        }

        if (data?.url) {
            this.router.navigateByUrl(data.url);
        }
        
    }

    hideModal() {
        this.visible.next(false);
        this.modalData.next(undefined);
    }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hi-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input()
  color: string = 'white';

  @Input()
  size: number = 64;

  @Input()
  thickness: number = 4;

  constructor() {}

  ngOnInit(): void {}
}

<div
  class="page full layout-vertical-space-between submission-form-page"
  [ngStyle]="{
    'background-image': 'url(' + page.page_background_image_url + ')'
  }"
>
  <div *ngIf="page.page_logo_image_url" class="logo-wrapper">
    <img [src]="page.page_logo_image_url" [alt]="boothName" />
  </div>

  <div class="inner-content">
    <h1 *ngIf="page.title && !loading" [innerHTML]="page.title"></h1>
    <p *ngIf="page.copy && !loading" class="base-copy" [innerHTML]="page.copy"></p>

    <form *ngIf="!loading" #form="ngForm" novalidate (ngSubmit)="submit()" class="form">
      <div
        class="form-control"
        [class.invalid]="formWasSubmitted && ngForm?.controls?.name?.invalid"
      >
        <input
          type="text"
          name="name"
          [(ngModel)]="formData.name"
          placeholder="Your Name"
          [required]="true"
        />
      </div>

      <div
        class="form-control"
        [class.invalid]="formWasSubmitted && ngForm?.controls?.email?.invalid"
      >
        <input
          type="email"
          name="email"
          [(ngModel)]="formData.email"
          placeholder="Your Email"
          [required]="true"
          [email]="true"
        />
      </div>

      <div
        class="form-control checkbox"
        [class.invalid]="formWasSubmitted && ngForm?.controls?.terms?.invalid"
      >
        <input
          type="checkbox"
          name="terms"
          [(ngModel)]="formData.terms"
          [required]="true"
        />
        <label class="form-label" for="terms" (click)="showTerms()">I agree to the Terms & Conditions</label>
      </div>

      <div *ngIf="buttons?.length" class="buttons-wrapper">
        <hi-buttons [buttons]="buttons" (buttonClickEmit)="buttonClick($event)"></hi-buttons>
      </div>
    </form>
    <div *ngIf="loading">
      <hi-loader [size]="64" [thickness]="4" [color]="'var(--primary-color)'"></hi-loader>
    </div>
  </div>

  <hi-powered-by *ngIf="poweredByEnabled" [black]="poweredByDark"></hi-powered-by>

  <div *ngIf="page.page_footer_image_url" class="page-footer">
    <img [src]="page.page_footer_image_url" alt="Footer Image">
  </div>
</div>

<video class="capture-video" [srcObject]="stream" playsinline></video>
<canvas class="capture-canvas" [class.show]="videoReady"></canvas>
<div class="overlay-container">
  <img
    *ngIf="this.assets.overlayImg"
    class="overlay-image"
    crossorigin="anonymous"
    [src]="this.assets.overlayImg.src"
  />
  <img
    *ngIf="this.assets.borderImg"
    class="overlay-image"
    crossorigin="anonymous"
    [src]="this.assets.borderImg.src"
  />
  <img
    *ngIf="this.assets.stickerImg"
    class="overlay-image"
    crossorigin="anonymous"
    [src]="this.assets.stickerImg.src"
  />
  <div class="fps" *ngIf="developerMode">
    <p>FPS: {{ this.fps | number : "1.0-0" }}</p>
    <p (click)="this.faceMeshPerformanceMode = !this.faceMeshPerformanceMode">
      Face Mesh Performance Mode:
      {{ this.faceMeshPerformanceMode ? "On" : "Off" }}
    </p>
  </div>
</div>

import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { HoudiniCameraComponent } from 'src/app/components/camera/camera.component';
import { HoudiniVideoComponent } from 'src/app/components/video/video.component';
import { isMobile } from 'src/app/functions/is-mobile';
import { Effect } from 'src/app/models/effect';
import { EffectGroup } from 'src/app/models/effect-group';
import { EffectType } from 'src/app/models/effect-type';
import { CameraFeedPageData } from 'src/app/models/page-data-types/camera-feed-page-data';
import { ScreenshotType } from 'src/app/models/screenshot-mode.type';

@Component({
  selector: 'hi-camera-feed-page',
  templateUrl: './camera-feed-page.component.html',
  styleUrls: ['./camera-feed-page.component.scss'],
})
export class CameraFeedPageComponent
  extends PageBase<CameraFeedPageData>
  implements OnInit
{
  @ViewChild('camera')
  camera: HoudiniCameraComponent;

  @ViewChild('video')
  video: HoudiniVideoComponent;

  public showModal: boolean = false;

  public welcomeModalWereClosed: boolean = false;

  public currentCameraFacingMode: 'user' | 'environment' = 'user';

  public isEffectGroupsSelectorOpened: boolean = false;

  public streamConstraints: MediaStreamConstraints = {
    video: {
      facingMode: 'user',
    },
  };

  public videoReady: boolean = false;

  public selectedFilter: Effect = null;

  public selectedOverlay: Effect = null;

  public selectedBorder: Effect = null;

  public selectedSticker: Effect = null;

  public selectedBackground: Effect = null;

  public selectedFaceEffect: Effect = null;

  public effectGroups: EffectGroup[] = [];

  public selectedGroup: EffectGroup = null;

  public screenshotType: ScreenshotType = 'image';

  public videoRecording: boolean = false;

  public canRecord: boolean = false;

  constructor(private injector: Injector) {
    super(injector);
    this.effectGroups = this.page.effect_groups;
    this.selectedGroup = this.effectGroups[0];
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.welcomeModalWereClosed = true;
    }, 500);

    // const dontShowWelcomeModal = localStorage.getItem('dontShowWelcomeModal');
    // if (dontShowWelcomeModal) {
    //   setTimeout(() => {
    //     this.welcomeModalWereClosed = true;
    //   }, 1000);
    // } else {
    //   setTimeout(() => {
    //     this.showModal = true;
    //   }, 1000);
    // }
  }

  public switchCameras() {
    if (!this.isMobile) return;

    this.videoReady = false;
    this.video.videoReady = false;
    setTimeout(async () => {
      await this.video.stopStream();
      this.camera.destroy();
      setTimeout(async () => {
        this.currentCameraFacingMode =
          this.currentCameraFacingMode === 'user' ? 'environment' : 'user';

        this.streamConstraints = {
          video: {
            facingMode: this.currentCameraFacingMode,
          },
        };

        this.camera.init(this.streamConstraints);
        await this.video.checkstream();
      });
    }, 300);
  }

  public changeScreenshotMode() {
    if (this.videoRecording) return;
    this.screenshotType = this.screenshotType === 'image' ? 'video' : 'image';
  }

  public uploadPicture(): void {
    console.log('need to implement');
  }

  public selectGroup(group: EffectGroup) {
    this.selectedGroup = group;
    this.isEffectGroupsSelectorOpened = false;
  }

  public onEffectChanged(effect: Effect, groupType: EffectType) {
    this['selected' + groupType.replace(' ', '')] = effect;
  }

  public async onTakePictureTap(mode: ScreenshotType = 'image') {
    if (mode === 'image') {
      const screenshot = await this.video.takeScreenshot();

      localStorage.setItem('screenshot', screenshot);
      localStorage.setItem('screenshot_type', 'image');
      this.goToNextPage();
    } else {
      this.videoRecording = true;
      const video = await this.video.takeVideo(5000);

      localStorage.setItem('screenshot', video);
      localStorage.setItem('screenshot_type', 'video');
      this.goToNextPage();

      // var link$ = document.createElement('a');
      // link$.setAttribute('download', 'recordingVideo');
      // link$.setAttribute('href', video);
      // link$.click();
    }
  }

  public closeWelcomeModal() {
    this.showModal = false;
    this.welcomeModalWereClosed = true;
    localStorage.setItem('dontShowWelcomeModal', 'true');
  }

  public get isMobile() {
    return isMobile();
  }
}

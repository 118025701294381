import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { APP_BASE_HREF, Location, PlatformLocation } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { getBasePath } from './functions/get-base-path';
import { UnsafeHTMLPipe } from './helpers/unsafeHTMLPipe';

import * as Hammer from 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  LandingPageComponent,
  CameraFeedPageComponent,
  ThankYouPageComponent,
  GalleryComponent,
  GalleryEmbedComponent,
  GatePageComponent,
  WelcomePageComponent,
  CameraAccessPromptPageComponent,
  UserInformationPageComponent,
  SubmissionPageComponent,
  BottomControlsComponent
} from './pages';

import { ButtonsComponent } from './components/buttons/buttons.component';
import { ModalComponent } from './components/modal/modal.component';
import { IconComponent } from './components/icon/icon.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HoudiniCameraComponent } from './components/camera/camera.component';
import { HoudiniVideoComponent } from './components/video/video.component';
import { DataCaptureModalComponent } from './components/data-capture-modal/data-capture-modal.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    HoudiniCameraComponent,
    HoudiniVideoComponent,
    AppComponent,
    LandingPageComponent,
    CameraFeedPageComponent,
    UserInformationPageComponent,
    BottomControlsComponent,
    ModalComponent,
    ButtonsComponent,
    ThankYouPageComponent,
    IconComponent,
    GalleryComponent,
    GalleryEmbedComponent,
    GatePageComponent,
    WelcomePageComponent,
    CameraAccessPromptPageComponent,
    SubmissionPageComponent,
    PoweredByComponent,
    LoaderComponent,
    UnsafeHTMLPipe,
    DataCaptureModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HammerModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBasePath,
      deps: [PlatformLocation]
    }
  ],
})
export class AppModule {}

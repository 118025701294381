import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { GatePageData } from 'src/app/models/page-data-types/gate-page-data';
import { HttpService } from 'src/app/services/http.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
    selector: 'app-gallery-embed',
    templateUrl: './gallery-embed.component.html',
    styleUrls: ['./gallery-embed.component.scss']
})
export class GalleryEmbedComponent extends PageBase<GatePageData> implements OnInit {

    public cachedImages: Array<any> = null;
    public allImages: Array<any> = [];
    public images: Array<any> = [];
    public loading: boolean = true;
    public end: boolean = false;
    public pageNumber: number = 1;
    public numToLoad: number = 12;

    constructor(private injector: Injector, private httpSvc: HttpService, private configSvc: ConfigurationService) {
        super(injector);
    }

    ngOnInit(): void {
        this.configurationService.useFullPage$.next(true);
        this.fetchImages();
    }

    public fetchImages(): void {
        if (this.cachedImages === null) {
            this.httpSvc.getGalleryImages(this.boothName).subscribe((data: any) => {
                this.loading = false;
                this.cachedImages = data.uploads;
                this.pageImages();
            });
        } else {
            this.loading = false;
            this.pageImages();
        }
    }

    public pageImages(): void {
        if (!this.cachedImages || !this.cachedImages.length) {
            this.end = true;
            return;
        }

        let maxPages = Math.ceil(this.cachedImages.length / this.numToLoad);
        if (this.pageNumber > maxPages) {
            this.end = true;
            return;
        }

        let start = (this.pageNumber - 1) * this.numToLoad;
        let end = this.pageNumber * this.numToLoad;

        if (end > this.cachedImages.length) {
            end = this.cachedImages.length;
        }

        let imagesPage = this.cachedImages.slice(start, end);
        this.allImages.push(...imagesPage);
        this.loadMore();
    }

    public onScroll(): void {
        let container: HTMLElement = document.querySelector('.images');

        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
            this.fetchMore();
        }
    }

    public fetchMore():void {
        if (this.loading || this.end) {
            return;
        }
        this.pageNumber++;
        this.fetchImages();
    }

    public loadMore(): void {
        if (this.loading || this.end) {
            return;
        }

        this.loading = true;

        if (this.allImages.length) {
            this.images = this.allImages;

            let loadedCount = 0;
            this.allImages.forEach(el => {
                if (el.loaded) {
                    loadedCount++;
                } else {
                    const img = new Image();
                    img.src = el.url;
                    img.onload = () => {
                        el.loaded = true;
                        loadedCount++;
                    };
                }
            });
        }

        this.loading = false;
    }
}

<div
  class="page full layout-vertical-space-between thank-you-page"
  [ngStyle]="{
    'background-image': 'url(' + page.page_background_image_url + ')'
  }"
>
  <div *ngIf="page.page_logo_image_url" class="logo-wrapper">
    <img [src]="page.page_logo_image_url" [alt]="boothName" />
  </div>

  <div class="inner-content">
    <h1 *ngIf="page.title" [innerHTML]="page.title"></h1>
    <p class="base-copy" *ngIf="page.copy" [innerHTML]="page.copy"></p>
    <h2 *ngIf="page.hash_tags" class="hash-tags" [innerHTML]="page.hash_tags"></h2>

    <div *ngIf="buttons?.length" class="buttons-wrapper">
      <hi-buttons [buttons]="buttons" (buttonClickEmit)="buttonClick($event)"></hi-buttons>
    </div>

    <!-- <button *ngIf="this.canShare()" id="download-btn" class="btn primary" (click)="share()">Download</button> -->

    <a *ngIf="this.canShare()" class="download-btn can-share" href="#" (click)="$event.preventDefault(); share()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.4 47.4" xml:space="preserve">
        <path d="M4.4 25.4 19 36c.2.1.3.2.5.3h.1c.2 0 .3.1.5.1h.2c.2 0 .3 0 .5-.1h.1c.2-.1.3-.1.5-.3L36 25.4c.9-.7 1.1-1.9.4-2.8-.7-.9-1.9-1.2-2.9-.5l-11.3 8.2V2.1c0-1.1-.9-2.1-2.1-2.1C19 0 18 .9 18 2.1v28.2L6.8 22.1c-.9-.7-2.2-.5-2.9.5-.6.8-.4 2.1.5 2.8z"/>
        <path d="M40.4 45.3v-9.1c0-1.1-.9-2.1-2.1-2.1-1.1 0-2.1.9-2.1 2.1v7.1H4.1v-7.1c0-1.1-.9-2.1-2.1-2.1-1.1 0-2 .9-2 2V45.8c.2.9 1 1.5 2 1.5h36.2c1.2.1 2.2-.9 2.2-2z"/>
      </svg>

      <span>Download my image</span>
    </a>

    <a *ngIf="!this.canShare()" class="download-btn no-share" href="#" (click)="$event.preventDefault(); download()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.4 47.4" xml:space="preserve">
        <path d="M4.4 25.4 19 36c.2.1.3.2.5.3h.1c.2 0 .3.1.5.1h.2c.2 0 .3 0 .5-.1h.1c.2-.1.3-.1.5-.3L36 25.4c.9-.7 1.1-1.9.4-2.8-.7-.9-1.9-1.2-2.9-.5l-11.3 8.2V2.1c0-1.1-.9-2.1-2.1-2.1C19 0 18 .9 18 2.1v28.2L6.8 22.1c-.9-.7-2.2-.5-2.9.5-.6.8-.4 2.1.5 2.8z"/>
        <path d="M40.4 45.3v-9.1c0-1.1-.9-2.1-2.1-2.1-1.1 0-2.1.9-2.1 2.1v7.1H4.1v-7.1c0-1.1-.9-2.1-2.1-2.1-1.1 0-2 .9-2 2V45.8c.2.9 1 1.5 2 1.5h36.2c1.2.1 2.2-.9 2.2-2z"/>
      </svg>

      <span>Download my image</span>
    </a>
  </div>

  <hi-powered-by *ngIf="poweredByEnabled" [black]="poweredByDark"></hi-powered-by>

  <div *ngIf="page.page_footer_image_url" class="page-footer">
    <img [src]="page.page_footer_image_url" alt="Footer Image">
  </div>
</div>

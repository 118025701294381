<!-- class="" -->
<div
  class="modal-container"
  [class.show]="show"
  [class.desktop]="!isMobile"
  (swipeup)="close()"
>
  <div class="modal">
    <button class="modal-close-btn" (tap)="close()">&times;</button>

    <h1 class="modal-title" [innerHTML]="title"></h1>

    <div class="modal-body" [innerHTML]="copy | unsafeHtml"></div>
  </div>
</div>

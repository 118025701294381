import { AfterViewInit, Component, OnInit } from '@angular/core';
import { isMobile } from './functions/is-mobile';
import { ConfigurationService } from './services/configuration.service';
import { HoudiniAnalyticsService } from 'houdini-analytics/dist/houdini-analytics';
import { NavigationEnd, Router, Routes } from '@angular/router';
import { Configuration, Font } from './models/configuration';
import { PageRoutes } from './constants/page-routes.const';
import { Title } from '@angular/platform-browser';

import {
  CameraAccessPromptPageComponent,
  CameraFeedPageComponent,
  GalleryComponent,
  GalleryEmbedComponent,
  GatePageComponent,
  LandingPageComponent,
  SubmissionPageComponent,
  ThankYouPageComponent,
  UserInformationPageComponent,
  WelcomePageComponent
} from './pages';
import { getBasePath } from './functions/get-base-path';

@Component({
  selector: 'hi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  public useFullPage: boolean = false;

  public configurationLoaded: boolean = false;

  public background: string = null;

  public boothName: string;

  constructor(
    private configurationService: ConfigurationService,
    private analyticsSvc: HoudiniAnalyticsService,
    private router: Router,
    private titleSvc: Title
  ) { }

  ngOnInit(): void {
    this.initConfig();
  }

  public ngAfterViewInit() {
    this.subscribe();
  }

  /**
   * Get base path and load configuration based on booth name
   */
  public initConfig(): void {
    // Get base href
    let name = getBasePath().replace('/', '');

    // Set local and service variables
    this.configurationService.boothName = name;
    this.boothName = name;

    // load config based on name
    this.configurationService.loadConfiguration();
  }

  /**
   * Listen for config loaded and router events
   */
  public subscribe(): void {
    this.configurationService.onConfigurationLoaded.subscribe((configuration) => {

      // Set favicon if logo is passed
      if (configuration.logo) {
        const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
        favIcon.href = configuration.logo;
      }

      // Page background CSS variable
      if (configuration.default_page_background) {
        document.documentElement.style.setProperty(
          '--default-page-bg-img',
          `url('${configuration.default_page_background}')`
        );
      }

      // Set html title based on name
      this.titleSvc.setTitle(configuration.name + ' Photobooth');

      // Add routes
      this.initRouting(configuration);

      // Check for and set theme bg image
      if (configuration.pages[1]) {
        this.background = configuration.pages[1].data.page_background_image_url;
      }

      // Check for analytics from theme and initialize
      if (configuration.analytics &&
        configuration.analytics.trackingType &&
        configuration.analytics.id
      ) {
        this.analyticsSvc.initTracking(true, configuration.analytics.trackingType, configuration.analytics.id);
        this.configurationService.trackingEnabled.next(true);
      }

      // Add fonts
      if (configuration.fonts) {
        this.addFonts(configuration.fonts);
      }

      // Add color variables
      for (const key in configuration.colors) {
        if (configuration.colors[key].type === 'color') {
          document.documentElement.style.setProperty(
            '--' + key,
            configuration.colors[key].value
          );
        }

        if (configuration.colors[key].type === 'variable') {
          document.documentElement.style.setProperty(
            '--' + key,
            `var(--${configuration.colors[key].value})`
          );
        }
      }

      this.configurationLoaded = true;
    });

    // If analytics added, send page views
    this.configurationService.trackingEnabled.subscribe(enabled => {
      if (enabled) {
        this.router.events.subscribe((val) => {
          if (val instanceof NavigationEnd) {
            this.analyticsSvc.trackPageView(val.url);
          }
        });
      }
    });

    // Detect if content should be displayed in full-page mode.
    this.configurationService.useFullPage$.subscribe((enabled) => {
      this.useFullPage = enabled;
    });

  }

  /**
   * Add routes based on page data
   * @param {Configuration} configuration - data for booth
   */
  public initRouting(configuration: Configuration): void {
    const allRoutes: Routes = [
      { path: PageRoutes.LANDING, component: LandingPageComponent },
      { path: PageRoutes.GALLERY, component: GalleryComponent },
      { path: PageRoutes.GALLERY_EMBED, component: GalleryEmbedComponent },
      { path: PageRoutes.GATE, component: GatePageComponent },
      { path: PageRoutes.WELCOME, component: WelcomePageComponent },
      { path: PageRoutes.CAMERA_ACCESS, component: CameraAccessPromptPageComponent },
      { path: PageRoutes.USER_INFORMATION, component: UserInformationPageComponent },
      { path: PageRoutes.CAMERA_FEED, component: CameraFeedPageComponent },
      { path: PageRoutes.SUBMISSION, component: SubmissionPageComponent },
      { path: PageRoutes.THANK_YOU, component: ThankYouPageComponent }
    ];

    const newRoutes: Routes = [];

    configuration.pages.forEach(page => {
      let data = allRoutes.find(r => r.path === page.path);
      if (data) {
        newRoutes.push(data);
      }
    });

    // Add catchall
    newRoutes.push({ path: '**', redirectTo: '/' });

    // Add routes and initiate
    this.router.resetConfig(newRoutes);
    this.router.initialNavigation();
  }

  /**
   * Add fonts from configuration
   * @param {Font[]} fonts - list of font file urls from config
   */
  public addFonts(fonts: Font[]): void {
    // Loop through each font
    for (let font of fonts) {
      let newFont: string = `@font-face { font-family: ${font.name}; src: `;
      for (let i = 0; i < font.files.length; i++) {
        const currFont: string = font.files[i];
        if (font.files[i].indexOf('googleapis')) {
          const link = document.createElement('link');
          link.setAttribute('href',currFont);
          link.setAttribute('rel', 'stylesheet');
          document.getElementsByTagName('head')[0].appendChild(link);
        } else {
          // Actual Font files not URLS to a service. 
          const fileSplits: string[] = currFont.split('.');
          const fileType: string = fileSplits[fileSplits.length - 1].toLowerCase();
          const fontFileTypes: string[] = ['ttf', 'otf', 'woff', 'woff2', 'eot', 'svg'];
          // Make sure file is a font before adding
          if (fontFileTypes.includes(fileType)) {
            let format: string = `format('${fileType}')`;

            // Change format name for some font file types
            switch (fileType) {
              case 'eot':
                format = `format('embedded-opentype')`;
                break;

              case 'ttf':
                format = `format('truetype')`;
                break;
            }

            // Add font file to list of urls to import
            newFont += `url(${font.files[i]}) ${format}`;
          }
          // End url list if last file, otherwise add comma
          if (i === font.files.length - 1) {
            newFont += '; }'
          } else {
            newFont += ', '
          }
          // Create new stylesheet and append to head
          const style = document.createElement('style');
          style.innerHTML = newFont;
          document.getElementsByTagName('head')[0].appendChild(style);
        }
      }
      // Set global variable for font type
      document.documentElement.style.setProperty(`--font-${font.type}`, font.name);
    }
  }

  public get isMobile() {
    return isMobile();
  }
}
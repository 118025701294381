<div
  class="lds-ring"
  [ngStyle]="{
    'width.px': size + thickness * 2,
    'height.px': size + thickness * 2
  }"
>
  <div
    [ngStyle]="{
      'border-top-color': color,
      'width.px': size,
      'height.px': size,
      'margin.px': thickness,
      'border-width.px': thickness
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-top-color': color,
      'width.px': size,
      'height.px': size,
      'margin.px': thickness,
      'border-width.px': thickness
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-top-color': color,
      'width.px': size,
      'height.px': size,
      'margin.px': thickness,
      'border-width.px': thickness
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-top-color': color,
      'width.px': size,
      'height.px': size,
      'margin.px': thickness,
      'border-width.px': thickness
    }"
  ></div>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hi-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss'],
})
export class PoweredByComponent implements OnInit {
  @Input() black: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  public houdiniLink(): void {
    window.open(`https://houdini.studio?referrer=${window.location.href}`, '_blank');
  }
}

export const PageRoutes = {
  LANDING: '',
  GALLERY: 'gallery',
  GALLERY_EMBED: 'gallery-embed',
  GATE: 'gate',
  WELCOME: 'welcome',
  CAMERA_ACCESS: 'camera-access',
  USER_INFORMATION: 'user-information',
  CAMERA_FEED: 'take',
  SUBMISSION: 'submission',
  THANK_YOU: 'thank-you',
};

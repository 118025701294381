import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { CameraAccessPageData } from 'src/app/models/page-data-types/camera-access-page-data';

@Component({
  selector: 'hi-camera-access-prompt-page',
  templateUrl: './camera-access-prompt-page.component.html',
  styleUrls: ['./camera-access-prompt-page.component.scss'],
})
export class CameraAccessPromptPageComponent
  extends PageBase<CameraAccessPageData>
  implements OnInit
{
  public promptForCameraAccess: boolean = false;

  public cameraAccessStatus: 'PENDING' | 'ALLOWED' | 'DECLINED' = 'PENDING';

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    const cameraAccessAlreadyAllowed = localStorage.getItem(
      'cameraAccessAllowed'
    );

    if (!cameraAccessAlreadyAllowed) {
      this.promptForCameraAccess = true;
    } else {
      this.goToNextPage(true);
    }
  }

  public onCameraAccessAllowed() {
    this.cameraAccessStatus = 'ALLOWED';
    localStorage.setItem('cameraAccessAllowed', 'true');
    setTimeout(() => {
      this.goToNextPage(true);
    }, 1000);
  }

  public onCameraAccessDeclined() {
    this.cameraAccessStatus = 'DECLINED';
  }
}

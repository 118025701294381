import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { Button } from 'src/app/models/configuration';
import { GatePageData } from 'src/app/models/page-data-types/gate-page-data';

@Component({
  selector: 'hi-gate-page',
  templateUrl: './gate-page.component.html',
  styleUrls: ['./gate-page.component.scss'],
})
export class GatePageComponent extends PageBase<GatePageData> implements OnInit {
  public userHaveDeclined: boolean = false;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.buttons) {
      this.buttons.forEach(button => {
        if (button.custom_css.visibility === 'hidden') {
          button.type += ' gate-declined-only';
        }
      });
    }
  }

  public buttonClick(button: Button): void {
    if (button.link === 'decline-gate') {
      this.userHaveDeclined = true;
    } else {
      super.buttonClick(button);
    }
  }
}

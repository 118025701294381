<div class="modal-container"
    [class.show]="show">
    <button class="modal-close-btn" (tap)="close()">&times;</button>
    <div class="submission-form-inner">
        <h1 *ngIf="title" [innerHTML]="title"></h1>
        <p *ngIf="copy" class="base-copy" [innerHTML]="copy"></p>

        <form #form="ngForm" novalidate (ngSubmit)="submit()" class="form">
        <div
            class="form-control"
            [class.invalid]="formWasSubmitted && ngForm?.controls?.name?.invalid"
        >
            <input
            type="text"
            name="name"
            [(ngModel)]="formData.name"
            placeholder="Your Name"
            [required]="true"
            />
        </div>

        <div
            class="form-control"
            [class.invalid]="formWasSubmitted && ngForm?.controls?.email?.invalid"
        >
            <input
            type="email"
            name="email"
            [(ngModel)]="formData.email"
            placeholder="Your Email"
            [required]="true"
            [email]="true"
            />
        </div>

        <div
            class="form-control checkbox"
            [class.invalid]="formWasSubmitted && ngForm?.controls?.terms?.invalid"
        >
            <input
            type="checkbox"
            name="terms"
            [(ngModel)]="formData.terms"
            [required]="true"
            />
            <label class="form-label" for="terms" (click)="showTerms()">I agree to the Terms & Conditions</label>
        </div>



        <div class="buttons">
            <button class="btn primary" (click)="submit()">SUBMIT</button>
        </div>
        </form>
    </div>
</div>
import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Button, Configuration, Modal, PageConfig } from '../models/configuration';
import { ConfigurationService } from '../services/configuration.service';
import { ModalService } from '../services/modal.service';

export abstract class PageBase<PageDataType> {
  public page: PageDataType = null;
  public disabled: boolean = false;
  public poweredByEnabled: boolean = true;
  public poweredByDark: boolean = false;
  public boothName: string = 'houdini';
  public modal: Modal = undefined;
  public buttons: Button[] = undefined;

  protected configurationService: ConfigurationService;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected modalSvc: ModalService

  private configuration: Configuration = null;

  constructor(injector: Injector) {
    this.configurationService = injector.get(ConfigurationService);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.modalSvc = injector.get(ModalService);
    this.configuration = this.configurationService.configuration;
    this.boothName = this.configurationService.boothName;
    let currentPath = this.activatedRoute.snapshot.routeConfig.path;

    if (currentPath === this.boothName) {
      currentPath = '';
    } else {
      currentPath = currentPath.replace(`/${this.boothName}`, '');
    }

    const pageConfig = this.configuration.pages.find(
      (x) => x.path === currentPath
    );

    this.disabled = pageConfig.disabled || false;
    this.poweredByEnabled = pageConfig.powered_by_enabled;
    this.poweredByDark = pageConfig.powered_by_dark;
    this.modal = pageConfig.modal;
    
    this.setupButtons(pageConfig);

    if (pageConfig.data) {
      this.page = pageConfig.data as PageDataType;
    }

    if (this.disabled && (this.page as any).next_page_route) {
      this.goToNextPage(true);
    }
  }

  private setupButtons(pageConfig: PageConfig) {
    this.buttons = pageConfig.buttons;
    if (this.buttons) {
      this.buttons.forEach(button => {
        // If there is custom css, convert to object
        if (button.custom_css && button.custom_css !== '' && typeof button.custom_css === 'string') {
          let result = {};
          // Remove whitespace from string and split by semicolon
          let attributes = button.custom_css.replace(/\s/g, '').split(';');

          // Loop through items and create key value pair for css
          for (var i = 0; i < attributes.length; i++) {
            var entry = attributes[i].split(':');
            result[entry.splice(0,1)[0]] = entry.join(':');
          }

          button.custom_css = result;
        }
      });
    }
  }

  public goToNextPage(replaceUrl: boolean = false) {
    this.goToPage((this.page as any).next_page_route, replaceUrl);
  }

  public goToPage(path: string, replaceUrl: boolean = false) {
    this.router.navigate([path], {
      replaceUrl: replaceUrl,
    });
  }

  /**
   * Handle dynamic button clicks
   * @param {Button} button
   */
  public buttonClick(button: Button): void {
    switch (button.link) {
      case 'next-page':
        this.goToNextPage();
      break;

      case 'modal':
        if (this.modal) {
          this.openModal();
        }
      break;

      case 'external':
        if (button.external_link) {
          window.open(button.external_link);
        } else {
          console.warn('Button clicked but no external link was passed. ');
        }
      break;

      default:
        this.goToPage(button.link);
    }
  }

  public openModal(): void {
    this.modalSvc.setModalData({ title: this.modal.title, copy: this.modal.copy });
  }
}

<div
  class="page full overflow-hidden camera-feed-page"
  [ngStyle]="{
    'background-image': 'url(' + page.page_background_image_url + ')'
  }"
>
  <div *ngIf="page.page_logo_image_url" class="logo-wrapper">
    <img [src]="page.page_logo_image_url" [alt]="boothName" />
  </div>

  <div
    class="blur-overlay"
    [class.show]="isEffectGroupsSelectorOpened"
    (click)="isEffectGroupsSelectorOpened = false"
  ></div>

  <ng-container *ngIf="welcomeModalWereClosed">
    <hi-camera #camera [constraints]="streamConstraints"></hi-camera>

    <hi-video
      #video
      [developerMode]="false"
      [stream]="camera.stream"
      [flipVideo]="currentCameraFacingMode === 'user'"
      [filter]="selectedFilter"
      [overlay]="selectedOverlay"
      [border]="selectedBorder"
      [sticker]="selectedSticker"
      [background]="selectedBackground"
      [faceEffect]="selectedFaceEffect"
      (onVideoReady)="videoReady = true"
    ></hi-video>

    <div class="top-blur" [class.show]="videoReady"></div>

    <div
      class="photo-scope"
      [class.show]="
        videoReady &&
        selectedOverlay?.empty &&
        selectedBorder?.empty &&
        selectedSticker?.empty &&
        selectedBackground?.empty &&
        selectedFaceEffect?.empty
      "
      (swipeup)="switchCameras()"
      (swipedown)="switchCameras()"
      (swipeleft)="changeScreenshotMode()"
      (swiperight)="changeScreenshotMode()"
    ></div>

    <div class="bottom-blur" [class.show]="videoReady"></div>
  </ng-container>

  <div class="effect-groups" [class.show]="videoReady">
    <ng-container *ngFor="let group of effectGroups; let i = index">
      <hi-bottom-controls
        [show]="welcomeModalWereClosed && group.type === selectedGroup.type"
        [effects]="group.effects"
        (onEffectChanged)="onEffectChanged($event, group.type)"
        (onTakePictureTap)="onTakePictureTap()"
      >
      </hi-bottom-controls>
    </ng-container>
  </div>

  <div
    *ngIf="effectGroups.length > 1"
    class="effect-group-selector-dropup"
    [class.show]="isEffectGroupsSelectorOpened"
  >
    <ul>
      <li
        *ngFor="let group of effectGroups"
        class="btn-with-text"
        [class.selected]="group.type === selectedGroup.type"
        (click)="selectGroup(group)"
      >
        <div class="icon">
          <hi-icon *ngIf="group.iconSrc" [src]="group.iconSrc"></hi-icon>
          <hi-icon
            *ngIf="!group.iconSrc"
            src="../../../assets/icons/icon-filters.svg"
          ></hi-icon>
        </div>

        <span [innerHTML]="group.type + 's'"></span>
      </li>
    </ul>

    
    <button
      class="icon-btn btn-with-text more-filters-btn"
      [class.show]="videoReady"
      (click)="isEffectGroupsSelectorOpened = !isEffectGroupsSelectorOpened"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 46 46"
        xml:space="preserve"
      >
        <path
          d="M28.1 28.7c0 .7-.5 1.2-1.2 1.2h-9.6c-.7 0-1.2-.5-1.2-1.2v-9.6c0-.7.5-1.2 1.2-1.2h5.1v-2.4h-5.1c-2 0-3.6 1.6-3.6 3.6v9.6c0 2 1.6 3.6 3.6 3.6h9.6c2 0 3.6-1.6 3.6-3.6v-5.1h-2.4v5.1z"
        />
        <path
          d="m21.4 22.6-2 1 2 1 1 2 1-2 2-1-2-1-1-2zM28.4 21.5l1.3-2.6 2.6-1.3-2.6-1.3-1.3-2.6-1.3 2.6-2.6 1.3 2.6 1.3z"
        />
      </svg>

      <span [innerHTML]="selectedGroup.type + 's'"></span>
    </button>
  </div>
  <!-- end filter effects div -->

  <div class="buttons">
    <button
      *ngIf="isMobile"
      class="icon-btn circle-btn switch-cameras-btn"
      [class.show]="videoReady && !videoRecording"
      (click)="switchCameras()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 45 46"
        xml:space="preserve"
      >
        <path
          d="M22.5 15.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 3.3c-.5 0-.8-.4-.8-.8s.4-.8.8-.8.8.4.8.8-.3.8-.8.8z"
        />
        <path
          d="M29.1 18.4v-3.7c0-.5-.4-.8-.8-.8h-2.1l-1.4-1.4c-.2-.2-.4-.2-.6-.2h-3.3c-.2 0-.4.1-.6.2l-1.4 1.4h-2.1c-.5 0-.8.4-.8.8v3.6c-3.1 1.2-5 3.2-5 5.4 0 3.5 4.5 6.3 10.4 6.6l-1.9 1.9c-.2.2-.2.4-.2.6 0 .5.4.8.8.8.2 0 .4-.1.6-.2l3.3-3.3c.3-.3.3-.6.1-1 0 0 0-.1-.1-.1 0 0 0-.1-.1-.1l-3.3-3.3c-.3-.3-.8-.3-1.2 0s-.3.8 0 1.2l1.9 1.9c-4.8-.3-8.7-2.4-8.7-4.9 0-1.4 1.2-2.7 3.3-3.6V23c0 .5.4.8.8.8h11.6c.5 0 .8-.4.8-.8v-2.8c2.1.9 3.3 2.3 3.3 3.6 0 1.9-2.4 3.7-6 4.5-.4.1-.6.4-.6.8 0 .5.4.8.8.8h.2c4.4-1 7.2-3.4 7.2-6.1.1-2.2-1.8-4.2-4.9-5.4zm-11.6 3.8v-6.6h1.7c.2 0 .4-.1.6-.2l1.4-1.4h2.6l1.4 1.4c.2.2.4.2.6.2h1.7v6.6h-10z"
        />
      </svg>
    </button>
    <button
      *ngIf="this.canRecord"
      class="icon-btn circle-btn screenshot-mode-btn"
      [class.show]="videoReady"
      [class.animate]="!videoRecording"
      (click)="changeScreenshotMode()"
    >
      <hi-icon
        [class.show]="screenshotType === 'image' && !videoRecording"
        [src]="'../../../assets/icons/icon-camera-2.svg'"
        [color]="'white'"
      ></hi-icon>
      <hi-icon
        [class.show]="screenshotType === 'video' && !videoRecording"
        [src]="'../../../assets/icons/icon-recorder-2.svg'"
        [color]="'white'"
      ></hi-icon>
      <hi-icon
        [class.show]="screenshotType === 'video' && videoRecording"
        class="blink-animation"
        [src]="'../../../assets/icons/icon-recorder-2.svg'"
        [color]="'red'"
      ></hi-icon>
    </button>
  </div>
  <hi-powered-by *ngIf="poweredByEnabled" [black]="poweredByDark"></hi-powered-by>

  <div *ngIf="page.page_footer_image_url" class="page-footer">
    <img [src]="page.page_footer_image_url" alt="Footer Image" />
  </div>
</div>

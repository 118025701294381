import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { Results as FaceMeshResults } from '@mediapipe/face_mesh';
import { Mesh } from 'three';
import {
  FaceMeshEffectRendererOptions,
  FaceMeshModelOptions,
} from '../models/face-mesh-effect-renderer-options';
import { degToRad } from 'three/src/math/MathUtils';

export class FaceMeshEffectRenderer {
  private readonly FOV_DEGREES = 63;
  private readonly NEAR = 1;
  private readonly FAR = 10000;

  private readonly scene: THREE.Scene;
  private readonly renderer: THREE.WebGLRenderer;
  private readonly faceGroup: THREE.Group;

  private camera: THREE.Camera;

  private canvasElement: HTMLCanvasElement;

  private loader: GLTFLoader;

  private currentOptions: FaceMeshEffectRendererOptions = null;

  private _modelsBasePath: string = 'assets/photobooth/face-mesh/';

  constructor(canvasElement: HTMLCanvasElement, modelsBasePath?: string) {
    this._modelsBasePath = modelsBasePath;

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/'
    );

    this.loader = new GLTFLoader();
    this.loader.setDRACOLoader(dracoLoader);
    // this.loader.setPath(this._modelsBasePath);

    this.canvasElement = canvasElement;
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      canvas: canvasElement,
      alpha: true,
      antialias: true,
    });

    this.renderer.outputEncoding = THREE.sRGBEncoding;

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 100, 0);
    this.scene.add(hemiLight);

    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(-30, 100, -5);
    dirLight.castShadow = true;
    this.scene.add(dirLight);

    const dirLight2 = new THREE.DirectionalLight(0xffffff);
    dirLight2.position.set(-30, -100, -5);
    dirLight2.castShadow = true;
    this.scene.add(dirLight2);

    this.faceGroup = new THREE.Group();
    this.faceGroup.matrixAutoUpdate = false;
    this.scene.add(this.faceGroup);
  }

  private updatePath(path: string) {
    var outputPath = this._modelsBasePath;
    if (path.includes('http')) {
      outputPath = path;
    } else {
      outputPath += path;
    }

    return outputPath;
  }

  public loadModel(options: FaceMeshEffectRendererOptions) {
    this.currentOptions = options;
    this.faceGroup.clear();

    if (options.occluderModel) {
      this._loadOccluderModel(options.occluderModel).then(() => {
        this._loadModel(options.model);
      });
    } else {
      this._loadModel(options.model);
    }
  }

  public render(results: FaceMeshResults) {
    //console.log(`THREE CANVAS: ${this.canvasElement.width}:${this.canvasElement.height}`);
    this.onCanvasDimsUpdate();

    if (results.multiFaceGeometry.length > 0) {
      const faceGeometry = results.multiFaceGeometry[0];
      const poseTransformMatrixData = faceGeometry.getPoseTransformMatrix();

      this.faceGroup.matrix.fromArray(
        poseTransformMatrixData.getPackedDataList()
      );
      this.faceGroup.visible = true;
    } else {
      this.faceGroup.visible = false;
    }

    this.renderer.render(this.scene, this.camera);
  }

  private _loadModel(options: FaceMeshModelOptions) {
    var path = this.updatePath(options.path);

    this.loader.load(path, (gltf) => {
      console.log(gltf);
      if (this.currentOptions.model !== options) return;

      const model = gltf.scene;

      console.log(model);

      if (options.scale) {
        model.scale.set(options.scale.x, options.scale.y, options.scale.z);
      }

      if (options.position) {
        model.position.set(
          options.position.x,
          options.position.y,
          options.position.z
        );
      }

      if (options.rotation && options.rotation.x) {
        model.rotation.x = degToRad(options.rotation.x);
      }

      if (options.rotation && options.rotation.y) {
        model.rotation.y = degToRad(options.rotation.y);
      }

      if (options.rotation && options.rotation.z) {
        model.rotation.z = degToRad(options.rotation.z);
      }

      this.faceGroup.add(gltf.scene);
    });
  }

  private _loadOccluderModel(options: FaceMeshModelOptions): Promise<void> {
    const promise = new Promise<void>((resolve) => {
      var path = this.updatePath(options.path);

      this.loader.load(path, (occluderModelGlb) => {
        if (this.currentOptions.occluderModel !== options) return;

        const occluderModel = occluderModelGlb.scene;

        if (options.scale) {
          occluderModel.scale.set(
            options.scale.x,
            options.scale.y,
            options.scale.z
          );
        }

        if (options.position) {
          occluderModel.scale.set(
            options.scale.x,
            options.scale.y,
            options.scale.z
          );
        }

        if (options.rotation && options.rotation.x) {
          occluderModel.rotation.x = degToRad(options.rotation.x);
        }

        if (options.rotation && options.rotation.y) {
          occluderModel.rotation.y = degToRad(options.rotation.y);
        }

        if (options.rotation && options.rotation.z) {
          occluderModel.rotation.z = degToRad(options.rotation.z);
        }

        // Depth mask material
        const depthMaskMaterial = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: false,
          colorWrite: false,
        });

        const occluderMesh = occluderModel.children[0] as Mesh;

        occluderMesh.material = depthMaskMaterial;

        this.faceGroup.add(occluderModel);
        resolve();
      });
    });

    return promise;
  }

  private onCanvasDimsUpdate() {
    this.camera = new THREE.PerspectiveCamera(
      this.FOV_DEGREES,
      this.canvasElement.width / this.canvasElement.height,
      this.NEAR,
      this.FAR
    );

    this.renderer.setSize(this.canvasElement.width, this.canvasElement.height);
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { PageRoutes } from 'src/app/constants/page-routes.const';
import { Button } from 'src/app/models/configuration';
import { SubmissionPageData } from 'src/app/models/page-data-types/submission-page-data';
import { DataService } from 'src/app/services/data.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'hi-submission-page',
  templateUrl: './submission-page.component.html',
  styleUrls: ['./submission-page.component.scss'],
})
export class SubmissionPageComponent
  extends PageBase<SubmissionPageData>
  implements OnInit
{
  public screenshot: string = null;
  public loading: boolean = false;

  constructor(private injector: Injector, private httpSvc: HttpService, private dataSvc: DataService) {
    super(injector);

    this.dataSvc.dataConfirmed.subscribe((data) => {
      if(data) {
        this.upload(data);
        this.dataSvc.dataConfirmed.next(undefined);
      }
    })
  }

  ngOnInit(): void {
    this.screenshot = localStorage.getItem('screenshot');
  }

  public buttonClick(button: Button): void {
    if (button.link === 'submit-photo') {
      this.submit();
    } else {
      super.buttonClick(button);
    }
  }

  public submit() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let invalid: boolean = false;

    // let data = this.dataSvc.userData();
    // if(!data) {
      this.dataSvc.getUserData();
    // } else {
    //   console.log(data)
    //   // this.upload(data);
    // }

    // let data = {
      // name: localStorage.getItem('userName'),
      // email: localStorage.getItem('userEmail'),
      // file: localStorage.getItem('screenshot'),
      // site: this.boothName,
    // };

    // console.log('submitting', data);    
  }

  upload(data: any) {

    let userData = {
      name: data.name,
      email: data.email,
      file: localStorage.getItem('screenshot'),
      site: this.boothName,
    }

    this.httpSvc.submitGalleryImage(userData).subscribe(
      (data) => {
        this.loading = false;

        if (data.status === 'ok') {
          // Success message
          console.log('Success');
        } else {
          console.error('Upload failed with message: ' + data.message);
        }

        this.goToNextPage();
      },
      (error) => {
        this.loading = false;
        console.error(`Image upload failed. Error thrown: ${error.message}`);
        this.goToNextPage();
      }
    );
  }

  public canShare(): boolean {

    if(navigator.share) {
      return true
    }

    return false
  }

  public async share() {
    var blob = await( await fetch(this.screenshot) ).blob()
    var file = new File([blob], 'Houdini.jpg', { type: blob.type })

    if (navigator.share) {
      // @ts-ignore
      navigator.share({ files: [file] }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      console.log("CANT SHARE")
    }
  }

  public download() {
    var a = document.createElement('a');
    a.href = this.screenshot;
    a.download = 'Houdini.jpg';
    a.click();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HoudiniAnalyticsService } from 'houdini-analytics/dist/houdini-analytics';
import { Button } from 'src/app/models/configuration';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'hi-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
  public trackingEnabled: boolean = false;

  constructor(
    private configSvc: ConfigurationService,
    private analyticsSvc: HoudiniAnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscribe();
  }

  public subscribe(): void {
    this.configSvc.trackingEnabled.subscribe(enabled => {
      this.trackingEnabled = enabled;
    });
  }

  @Input() buttons: Button[];

  @Output() buttonClickEmit: EventEmitter<Button> = new EventEmitter<Button>();

  buttonClick(button: Button): void {
    if (this.trackingEnabled) {
      this.analyticsSvc.sendEventByData({
        action: 'button click',
        event_category: button.type,
        event_label: button.link
      });
    }

    this.buttonClickEmit.emit(button);
  }
}

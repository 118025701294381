import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isMobile } from 'src/app/functions/is-mobile';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'hi-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() public show: boolean = true;

  @Input() public title: string = '';
  @Input() public copy: string = '';

  // @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalSvc: ModalService) {}

  ngOnInit(): void {
    this.subscribe();
  }

  public subscribe(): void {
    this.modalSvc.visible.subscribe((visible) => {
      this.show = visible;
    });

    this.modalSvc.modalData.subscribe((data) => {
      if (data?.title) {
        this.title = data.title;
      }
      if (data?.copy) {
        this.copy = data.copy;
      }
    });
  }

  public close() {
    this.modalSvc.visible.next(false);
    // this.onClose.emit();
  }

  public get isMobile() {
    return isMobile();
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { LandingPageData } from 'src/app/models/page-data-types/landing-page-data';

@Component({
  selector: 'hi-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent
  extends PageBase<LandingPageData>
  implements OnInit
{
  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.goToNextPage();
    }, 2000);
  }
}

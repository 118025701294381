<div
  class="bottom-controls"
  [class.show]="show"
  (swipeleft)="moveCarouselRight()"
  (swiperight)="moveCarouselLeft()"
>
  <div class="bottom-controls-inner">
    <div class="selected-border"></div>
    <div class="effects-carousel">
      <div
        *ngFor="let effect of currentCarouselPage; trackBy: effectById"
        class="effect"
        (click)="selectEffect(effect)"
      >
        <div
          class="inner-color"
          [ngStyle]="{
            'background-image': 'url(' + effect.previewImage + ')'
          }"
        ></div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

const DEFAULT_CONSTRAINTS: MediaStreamConstraints = {
  audio: false,
  video: {
    facingMode: 'user',
    frameRate: { max: 60 },
    width: { ideal: 4096 },
    height: { ideal: 2160 },
  },
};

@Injectable({
  providedIn: 'root',
})
export class HiCameraService {
  private stream: MediaStream = undefined;

  public async initCamera(
    targetVideoElement?: HTMLVideoElement,
    constraints?: MediaStreamConstraints
  ): Promise<{ video: HTMLVideoElement; stream: MediaStream }> {
    this.stream = await navigator.mediaDevices.getUserMedia(
      constraints || DEFAULT_CONSTRAINTS
    );

    if (targetVideoElement) {
      targetVideoElement.srcObject = this.stream;
      // this.video.srcObject = this.localstream;
      this.stream.getTracks().forEach(el => {
        el.enabled = true;
      });
    }

    return new Promise((resolve) => {
      if (targetVideoElement) {
        targetVideoElement.onloadedmetadata = () => {
          targetVideoElement.width = targetVideoElement.videoWidth;
          targetVideoElement.height = targetVideoElement.videoHeight;
          targetVideoElement.play();
          targetVideoElement.addEventListener('loadeddata', (event) => {
            resolve({ video: targetVideoElement, stream: this.stream });
          });
        };
      } else {
        resolve({ video: null, stream: this.stream });
      }
    });
  }

  public destroyCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = undefined;
    }
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { PageBase } from 'src/app/classes/page-base.class';
import { PageRoutes } from 'src/app/constants/page-routes.const';
import { ThankYouPageData } from 'src/app/models/page-data-types/thank-you-page-data';

@Component({
  selector: 'hi-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
})
export class ThankYouPageComponent
  extends PageBase<ThankYouPageData>
  implements OnInit
{
  public screenshot: string = null;

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.screenshot = localStorage.getItem('screenshot');
  }


  public canShare(): boolean {

    if(navigator.share) {
      return true
    }

    return false
  }

  public async share() {
    var blob = await( await fetch(this.screenshot) ).blob()
    var file = new File([blob], 'Bath.jpg', { type: blob.type })

    if (navigator.share) {
      // @ts-ignore
      navigator.share({ files: [file] }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      console.log("CANT SHARE")
    }
  }

  public download() {
    var a = document.createElement('a');
    a.href = this.screenshot;
    a.download = 'Bath.jpg';
    a.click();
  }
}

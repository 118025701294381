<div *ngIf="configurationLoaded" class="app" [class.desktop]="!isMobile" [class.full-page]="useFullPage">
  <hi-data-capture-modal></hi-data-capture-modal>
  <hi-modal></hi-modal>
  <router-outlet></router-outlet>
  <div class="landscape-warning" *ngIf="!useFullPage">
    <h1>Please switch your device screen to portrait mode</h1>
  </div>
  <div class="desktop-browser-size-warning" *ngIf="!useFullPage">
    <h1>Please increase your browser viewport size</h1>
  </div>
</div>

<div class="app" [class.desktop]="!isMobile" *ngIf="!configurationLoaded">
  <div class="splash-screen">
    <hi-loader></hi-loader>
  </div>
</div>

import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PageBase } from 'src/app/classes/page-base.class';
import { Button, FormData } from 'src/app/models/configuration';
import { UserInformationPageData } from 'src/app/models/page-data-types/user-information-page-data';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'hi-submission-form-page',
  templateUrl: './user-information-page.component.html',
  styleUrls: ['./user-information-page.component.scss'],
})
export class UserInformationPageComponent
  extends PageBase<UserInformationPageData>
  implements OnInit
{
  @ViewChild('form') ngForm: NgForm;

  public formData: FormData = {
    name: '',
    email: '',
    terms: false
  };

  public formWasSubmitted: boolean = false;
  public loading: boolean = false;
  constructor(private injector: Injector, private httpSvc: HttpService) {
    super(injector);
  }

  ngOnInit(): void {
    this.formData.name = localStorage.getItem('userName') || '';
    this.formData.email = localStorage.getItem('userEmail') || '';
    this.formData.terms = localStorage.getItem('termsExcepted') ? true : false;
  }

  /**
   * Show Modal with terms and conditions
   */
  public showTerms(): void {
    console.log(this.modal);
    this.modalSvc.setModalData({ title: this.modal.title, copy: this.modal.copy });
  }

  public buttonClick(button: Button): void {
    if (button.link === 'submit-user-info-form') {
      this.submit();
    } else {
      super.buttonClick(button);
    }
  }

  /**
   * Submit form and validate
   */
  public submit(): void {
    console.log('Form submitted');
    console.log('Is Form Valid', this.ngForm.valid);

    this.formWasSubmitted = true;

    if (this.ngForm.invalid) return;

    console.log('Send submitted field here');

    localStorage.setItem('userName', this.formData.name);
    localStorage.setItem('userEmail', this.formData.email);
    localStorage.setItem('termsExcepted', this.formData.terms.toString());

    
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.upload(this.formData);
    // this.goToNextPage();
  }

  upload(data: any) {

    let userData = {
      name: data.name,
      email: data.email,
      file: localStorage.getItem('screenshot'),
      site: this.boothName,
    }

    this.httpSvc.submitGalleryImage(userData).subscribe(
      (data) => {
        this.loading = false;

        if (data.status === 'ok') {
          // Success message
          console.log('Success');
        } else {
          console.error('Upload failed with message: ' + data.message);
        }

        this.goToNextPage();
      },
      (error) => {
        this.loading = false;
        console.error(`Image upload failed. Error thrown: ${error.message}`);
        this.goToNextPage();
      }
    );
  }
}

import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'hi-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements AfterViewInit {
  @ViewChild('svg') svg: ElementRef;

  @Input() src: string;

  @Input() color?: string = 'white';

  @Input()
  cacheOn: boolean = true;

  private storage = window.localStorage;

  private prefix = 'cached_svg_image_';

  constructor(private http: HttpClient) {}

  ngAfterViewInit(): void {
    this.loadImage();
  }

  private loadImage() {
    if (this.cacheOn) {
      let cachedImage = this.storage.getItem(this.prefix + this.src);

      if (cachedImage) {
        cachedImage = this.changeIconColor(cachedImage);
        return this.onImageLoaded(cachedImage);
      }
    }

    const kill_me = this.http
      .get(this.src, { responseType: 'blob' })
      .subscribe((response) => {
        response.text().then((html) => {
          if (this.cacheOn) {
            this.storage.setItem(this.prefix + this.src, html);
          }

          html = this.changeIconColor(html);

          this.onImageLoaded(html);
          if (kill_me) {
            kill_me.unsubscribe();
          }
        });
      });
  }

  private onImageLoaded(html: string) {
    this.svg.nativeElement.innerHTML = html;

    if (this.svg.nativeElement.getElementsByTagName('svg').length) {
      this.svg.nativeElement
        .getElementsByTagName('svg')[0]
        .removeAttribute('width');
      this.svg.nativeElement
        .getElementsByTagName('svg')[0]
        .removeAttribute('height');
    }
  }

  private changeIconColor(iconHtml: string): string {
    if (this.color) {
      iconHtml = iconHtml.replace(
        new RegExp(this.escapeRegExp('#e7a8e9'), 'g'),
        getComputedStyle(document.documentElement).getPropertyValue(
          '--icon-btn-color'
        )
      );
    }
    return iconHtml;
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
